<template>    
  <main class="flex-grow-1 align-content-center">
    <div class=" h-100">
      <div class="container">
        <div>
            <div style="width: 0;" class="d-none d-lg-block mt-5">
                <h1 class="">Hello!</h1>
            </div>
            <h1 class="d-block d-lg-none mt-4">Hello</h1>
        </div>
        <div class="text-center">
          <h4>Lets get you started, speed up your Etsy packing process today.</h4>
        </div>

        <div class="">
          <div class="m-md-5 d-flex" >
            <div 
              @click="signin"
              class="my-auto h4 mx-auto clickable" 
              style="background-color: white;border: 3px solid black; border-radius:50px; height: 110px; line-height: 80px; padding: 5px 30px;">
              Sign in with <img class="img img-responsive" src="../assets/etsy.png"  style="margin-top:-6px; max-height: 100px" />
            </div>
          </div>
        </div>
      </div>

      <div class="bg-light drop mt-4">
        <div class="container pt-2 pb-5">
          <div style="width: 0;" class="d-none d-lg-block mt-5">
              <h1 class="">What?</h1>
          </div>
          <h1 class="d-block d-lg-none mt-4">How?</h1>
          <div>We automate the packing slip printing process for Etsy. No more wasted time having to manually find and print orders. Have them ready and waiting for you right at your printer.</div>
        </div>
      </div>

      <div class="">
        <div class="container pt-2 pb-5">
          <div style="width: 0;" class="d-none d-lg-block mt-5">
            <h1 class="">How?</h1>
          </div>
          <h1 class="d-block d-lg-none mt-4">How?</h1>
          <div>Once you sign in with Etsy, you will be granted our free tier of service. Our free teir checks your Etsy store for new orders, and automatically sends it to your inkjet / laser printer for automatic printing.</div>
          <div>Our easy onboarding system will teach you more and guide you through the process.</div>
          <div class="mt-4">Note: Currently only HP e-Print enabled printers are officially supported, we hope to add more soon, however please send us a message with your printer make and model and we will work out a solution. </div>
        </div>
      </div>

        <div class="bg-light drop mt-4">
          <div class="container pt-2 pb-5">
            <div style="width: 0;" class="d-none d-lg-block mt-5">
                <h1 class="">Why?</h1>
            </div>
            <h1 class="d-block d-lg-none mt-4">How?</h1>
            <div><a href="https://presspla.uk" target="presspla">I'm a small time Etsy seller</a>, and I got frustrated even just dealing with a couple orders a month, so I figured it would be better if my packing and shipping labels could be automatically printed based on SKU, so I made this micro-SaaS to hopefully scratch the same itch for other Etsy users.</div>
          </div>
        </div>
        
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  computed: {
    ...mapGetters(['selectedAccount'])
  },
  methods: {
    signin() {
      if (this.selectedAccount) return this.$router.push('/settings');
      window.location.href = `${process.env.VUE_APP_SERVER_BASE_URL}/EtsySignIn?redirect=${process.env.VUE_APP_UI_BASE_URL}` 
    }
  }
}
</script>
